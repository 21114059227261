<template>
    <div class="col-right">
        <a-spin :spinning="spinning">
            <div class="ng-star-inserted">
                <a-card>
                    <small slot="title">
                        订单管理

                    </small>
<!--                    <h4 slot="extra" class="ant-right">-->
<!--                        <a-icon type="copy"/>-->
<!--                        分销/自营规则-->
<!--                    </h4>-->
                    <div class="search-form">
                        <a-form>
                            <ul>
                                <li>
                                    <a-form-item label="订单号">
                                        <a-input v-model="queryParams.order_no" placeholder=""/>
                                    </a-form-item>
                                </li>
                                <li>
                                    <a-form-item label="外部订单号">
                                        <a-select style="width:210px;" v-model="queryParams.out_order_sn_empty"
                                                  default-value="0">
                                            <a-select-option value="0">
                                                全部
                                            </a-select-option>
                                            <a-select-option value="1">
                                                不为空
                                            </a-select-option>
                                            <a-select-option value="2">
                                                为空
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </li>
                                <li>
                                    <a-form-item label="下单日期">
                                        <a-range-picker v-model="queryParams.rangeOrderTime" value-format="YYYY-MM-DD"
                                                        style="width: 252px"/>
                                    </a-form-item>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    <a-form-item label="收件人">
                                        <a-input v-model="queryParams.express_name" placeholder=""/>
                                    </a-form-item>
                                </li>
                                <li>
                                    <a-form-item label="收件人电话">
                                        <a-input v-model="queryParams.express_phone" placeholder=""/>
                                    </a-form-item>
                                </li>
                                <li>
                                    <a-form-item label="商品名称">
                                        <a-input v-model="queryParams.goods_title" placeholder=""/>
                                    </a-form-item>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a-form-item label="商品编号">
                                        <a-input v-model="queryParams.goods_id" placeholder=""/>
                                    </a-form-item>
                                </li>
                                <!--                                <li>-->
                                <!--                                    <a-form-item label="商品分类">-->
                                <!--                                        <a-select v-model="queryParams.cate1" style="width: 120px">-->
                                <!--                                            <a-select-option v-for="item in category1" :value="item.id" :key="item.id">{{item.title}}</a-select-option>-->
                                <!--                                        </a-select>-->
                                <!--                                        <a-select v-model="queryParams.cate2" style="width: 120px">-->
                                <!--                                            <a-select-option v-for="item in category2" :value="item.id" :key="item.id">{{item.title}}</a-select-option>-->
                                <!--                                        </a-select>-->
                                <!--                                    </a-form-item>-->
                                <!--                                </li>-->
                            </ul>
                            <a-form-item>
                                <a-button type="primary" html-type="submit" @click="searchOrder">
                                    搜索
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </a-card>
                <a-card>
                    <a-tabs :tabBarGutter="0" size="small" @change="changeStatus">
                        <a-tab-pane key="0">
                        <span slot="tab">
                            <span>全部</span>
<!--                            <a-badge count="25">-->
<!--                                <span>&nbsp;&nbsp;</span>-->
<!--                            </a-badge>-->
                      </span>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="待发货">
                        </a-tab-pane>
                        <a-tab-pane key="4" tab="待收货">
                        </a-tab-pane>
                        <a-tab-pane key="5" tab="已完成">
                        </a-tab-pane>
<!--                        <a-button slot="tabBarExtraContent" style="font-size:12px; margin-left: 20px;">批量设置外部订单号</a-button>-->
<!--                        <a-button slot="tabBarExtraContent" style="font-size:12px; margin-left: 20px;">订单导出</a-button>-->
<!--                        <a-button slot="tabBarExtraContent" style="font-size:12px; margin-left: 20px;">导出记录</a-button>-->
                    </a-tabs>
                    <div class="ant-table ant-table-bordered">
                        <div class="ant-table-container ng-star-inserted">
                            <div class="ant-table-content">
                                <table div-content="" style="table-layout: auto;">
                                    <col class="ng-star-inserted">
                                    <col class="ng-star-inserted" style="width: 120px; min-width: 120px;">
                                    <col class="ng-star-inserted" style="width: 110px; min-width: 110px;">
                                    <col class="ng-star-inserted" style="width: 120px; min-width: 120px;">
                                    <col class="ng-star-inserted" style="width: 90px; min-width: 90px;">
                                    <col class="ng-star-inserted" style="width: 100px; min-width: 100px;">
                                    <thead class="ant-table-thead ng-star-inserted">
                                    <tr class="ant-table-row ng-star-inserted">
                                        <th class="ant-table-cell" style="text-align: center;">商品</th>
                                        <th class="ant-table-cell" style="text-align: center;">外部订单</th>
                                        <th class="ant-table-cell" style="text-align: center;">实付款</th>
                                        <th class="ant-table-cell" style="text-align: center;">收件人</th>
                                        <th class="ant-table-cell" style="text-align: center;">订单状态</th>
                                        <th class="ant-table-cell" style="text-align: center;">操作</th>
                                    </tr>
                                    </thead>
                                    <tbody class="ant-table-tbody ng-star-inserted">
                                    <template v-for="(item, idx) in orderList">

                                        <tr class="ant-table-expanded-row ng-star-inserted" :key="idx">
                                            <td class="nz-disable-td ant-table-cell" colspan="6">
                                                <div class="order-line ng-star-inserted">
                                                    <div class="order-line-number">
<!--                                                        <label style="margin-right: 2px;" class="ant-checkbox-wrapper ng-untouched ng-pristine ng-valid">-->
<!--                                                        <span class="ant-checkbox">-->
<!--                                                            <input type="checkbox"-->
<!--                                                                   class="ant-checkbox-input ng-untouched ng-pristine ng-valid">-->
<!--                                                            <span class="ant-checkbox-inner"></span></span><span></span></label>-->
                                                        订单号：{{item.order_no}}
                                                        <div

                                                                class="ng-tns-c228-16 ng-trigger ng-trigger-fadeMotion ant-tag-red ant-tag ng-star-inserted">
                                                            自营
                                                        </div>&nbsp;&nbsp;&nbsp;&nbsp; 下单时间：{{item.create_at}}&nbsp;&nbsp;&nbsp;
                                                        下单方式：开放平台&nbsp;&nbsp;&nbsp;&nbsp; 下单渠道: ????&nbsp;&nbsp;&nbsp;&nbsp;
                                                        配送方式：普通物流&nbsp;&nbsp;&nbsp;&nbsp; 支付方式: 预付款支付
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <template v-for="(goods, gidx) in item.orderList">

                                            <tr class="ant-table-row ng-star-inserted" :key="gidx">
                                                <td class="ant-table-cell">
                                                    <div class="order-goods" style="justify-content: space-between;">
                                                        <div style="display: flex; flex: 1;">
                                                            <div class="order-goods-img">
                                                                <img alt="" :src="goods.goods_logo">
                                                            </div>
                                                            <div class="order-goods-info">
                                                                <p style="margin: 0;">{{goods.goods_title}}</p>
                                                                <p class="center-space"><span class="ng-star-inserted">规格：{{goods.goods_spec}} </span>
                                                                </p>
                                                                <p style="margin: 0;">数量：{{goods.number_goods}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="order-goods-info"
                                                             style="text-align: right; width: 60px;">
                                                            <p style="margin: 0;" @click="applyRefundModal(idx)" v-if="item.refund_state == 0">
                                                                <label style="cursor: pointer; color: #E4AB73;" class="ng-star-inserted"> 申请售后 </label>
                                                            </p>
                                                            <p class="center-space"></p>
                                                                    <p style="margin: 0;"><label class="ng-star-inserted">{{item.refund_status_desc}}</label></p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td

                                                        rowspan="1"
                                                        class="ant-table-cell ng-star-inserted"
                                                        style="text-align: center;">{{item.outOrderSn}}
                                                </td>
                                                <td
                                                        rowspan="1"
                                                        class="ant-table-cell ng-star-inserted"
                                                        style="text-align: center;"><p style="margin: 0;">
                                                    ¥{{item.price_goods}}</p>
                                                    <p style="margin: 0;">+</p>
                                                    <p style="margin: 0;">运费：{{goods.price_express}}</p></td>
                                                <td
                                                        rowspan="1"
                                                        class="ant-table-cell ng-star-inserted"
                                                        style="text-align: center;"> {{item.express_name}}<br>
                                                    {{item.express_phone}}
                                                </td>
                                                <td
                                                        rowspan="1"
                                                        class="ant-table-cell ng-star-inserted"
                                                        style="text-align: center;"> {{item.status_desc}}
                                                </td>
                                                <td
                                                        rowspan="1"
                                                        class="ant-table-cell ng-star-inserted"
                                                        style="text-align: center;">
                                                    <div class="order-line-price"><a
                                                            nz-button="" nztype="link"
                                                            class="ant-btn ant-btn-link"
                                                            @click="$jumpTo('/buyer-detail/order/'+item.id+'/type/'+item.type)"><span
                                                            class="ng-star-inserted">查看详情</span></a></div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="pagination">
                        <a-pagination :current="queryParams.page" :total="total" :pageSize="queryParams.pageSize" @change="changePage" :show-total="total => `共 ${total} 条记录`" />
                    </div>
                </a-card>
            </div>
        </a-spin>

        <a-modal title="申请售后" :visible="showApplyRefund" width="800px"
                 @cancel="() => {showApplyRefund = false}"
                 :footer="null"
        >
            <a-form  :label-col = "{ span: 4 }" :wrapper-col = "{ span: 18 }" :form = "applyRefundForm"
                     @submit = "applyRefund">
                <a-form-item label="售后类型">
                    <a-radio-group v-decorator="['refund_type', {initialValue: refundTypeId}]" @change="changeRefundType">
                        <template v-if="applyRefundOrder.express_send_at">
                            <a-radio value="2">退货退款</a-radio>
                            <a-radio value="3">换货</a-radio>
                        </template>
                        <a-radio value="4">仅退款</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="售后原因">
                    <a-select placeholder="请选择" v-decorator="['reason_id', { rules: [
                                                {
                                                    required: true,
                                                    message: '请选择'
                                                }
                                            ]}]">
                        <a-select-option v-for="(item, idx) in refundReasonList.type" :value = "idx" :key="idx">
                            {{item}}
                        </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="问题描述">
                    <a-textarea v-decorator="['refund_desc', {initialValue:''}]" />
                </a-form-item>
                <a-form-item label = "上传图片">
                    <a-upload
                            name = "file"
                            list-type = "picture-card"
                            class = "avatar-uploader"
                            :show-upload-list = "false"
                            :action = "uploadUrl"
                            :before-upload = "beforeUpload"
                            @change = "uploadChange"
                            v-decorator="[
                              'refund_image',
                              {
                                valuePropName: 'file',
                                initialValue:''
                              },
                            ]"
                    >
                        <img v-if = "imageUrl" :src = "imageUrl" style = "width: 100%; height: 100%;"
                             alt = "avatar" />
                        <div v-else>
                            <a-icon :type = "uploadLoading ? 'loading' : 'plus'" />
                            <div class = "ant-upload-text">
                                Upload
                            </div>
                        </div>
                    </a-upload>
                </a-form-item>
                <a-table :columns = "applyGoodsColumn" :data-source = "applyRefundOrder.orderList" :pagination = "false">
                    <div slot = "goods" slot-scope="text, record">
                  <span><div  class="name-col ng-star-inserted" style="display: flex; white-space: normal; cursor: pointer;"><img  :src="record.goodsInfo.logo">
                      <dl>
                          <dt >{{ record.goodsInfo.title }}</dt>
                          <dd >规格：{{ record.goods_spec }}</dd>
                      </dl>
                  </div>
                  </span>
                    </div>
                </a-table>
                <div class = "modal-footer text-center">
                    <a-button type = "danger" html-type = "submit">提交</a-button>
                </div>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    const formItemLayout = {
        labelCol: {span: 4},
        wrapperCol: {span: 8},
    };
    const formTailLayout = {
        labelCol: {span: 4},
        wrapperCol: {span: 8, offset: 4},
    };

    export default {
        name: "order-list",
        data() {
            return {
                spinning: false,
                uploadLoading:false,
                uploadUrl:'',
                formItemLayout,
                formTailLayout,
                imageUrl:'',
                queryParams: {
                    status:0,
                    page: 1,
                    pageSize: 10
                },
                total:0,
                orderList: [],
                category1: [],
                category2: [],
                showApplyRefund: false,
                applyRefundOrder:{},
                refundType:{},
                refundTypeId: '2',
                applyGoodsColumn:[
                    {
                        title: '商品',
                        dataIndex: 'goods',
                        key: 'goods',
                        scopedSlots: {customRender: 'goods'},
                        width:'300px',
                    },
                    {
                        title: '单价',
                        dataIndex: 'price_selling',
                        key: 'price_selling',
                    },
                    {
                        title: '数量',
                        dataIndex: 'number_goods',
                        key: 'number_goods',
                        scopedSlots: {customRender: 'number_goods'},
                    },
                    {
                        title: '实付金额',
                        dataIndex: 'price_real',
                        key: 'price_real',
                    },
                    {
                        title: '运费',
                        dataIndex: 'price_express',
                        key: 'price_express',
                    }
                ],
            }
        },
        created() {
            this.uploadUrl = this.$apiBaseURL + '/api/index/uploadImg';
            this.getOrderList();
            this.getRefundType();
        },

        beforeCreate() {
            this.applyRefundForm = this.$form.createForm(this, {name: 'applyRefundForm'});
        },
        computed: {
            refundReasonList(){
                return this.refundType[this.refundTypeId];
            },
        },
        methods: {
            changeStatus(e){
                console.log(e);
                this.queryParams.status = e;
                this.queryParams.page = 1;
                this.getOrderList();
            },
            beforeUpload(file) {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    this.$message.error('You can only upload JPG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('Image must smaller than 2MB!');
                }
                return isJpgOrPng && isLt2M;
            },
            uploadChange(info){
                if (info.file.status === 'uploading') {
                    this.uploadLoading = true;
                    return;
                }
                if (info.file.status === 'done') {
                    console.log('image info:', info)
                    if (info.file.response.code == 1) {

                        this.imageUrl = info.file.response.data.url;
                    }
                    // Get this url from response in real world.
                    // getBase64(info.file.originFileObj, imageUrl => {
                    //     this.imageUrl = imageUrl;
                    //     this.uploadLoading = false;
                    // });
                }
            },
            changeRefundType(e){
                this.refundTypeId = e.target.value;
            },
            getRefundType(){
                service.post(service.uri.order.getRefundType,).then(res => {
                    if (res.code == 200) {
                        this.refundType = res.data
                    }
                });
            },
            applyRefundModal(idx){
                console.log(idx)
                this.applyRefundOrder = this.orderList[idx];
                this.refundTypeId = this.applyRefundOrder.express_send_at ? '2' : '4';
                this.showApplyRefund = true;
            },
            applyRefund(e){
                e.preventDefault();
                this.applyRefundForm.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                        this.spinning = true;
                        let data = Object.assign(values, {order_no: this.applyRefundOrder.order_no, refund_image:this.imageUrl});
                        service.post(service.uri.order.applyRefund, data).then(res => {
                            this.spinning = false;
                            if (res.code == 200) {
                                this.$message.success("提交成功");
                                this.showApplyRefund = false;
                                this.queryParams.page--;
                                this.getOrderList();
                            }
                        });
                    }
                });
            },
            searchOrder() {
                this.queryParams.page = 1;
                this.getOrderList();
            },
            getOrderList() {
                this.spinning = true;
                service.post(service.uri.order.getList, this.queryParams).then(res => {
                    if (res.code == 200) {
                        this.orderList = res.data.list;
                        this.total = res.data.total;
                    }
                    this.spinning = false;
                });
            },

            changePage(e){
                this.queryParams.page = e;
                this.getOrderList();
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/css/common";
    .name-col {
        img {
            display: block;
            width: 60px;
            height: 60px;
        }
        dl {
            margin: 0;
            list-style: none;
            padding: 0 0 0 10px;
            flex: 1;
            width: 0;
            dt {
                font-size: 14px;
                line-height: 24px;
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            dd {
                line-height: 18px;
                font-size: 12px;
                color: #666;
            }
        }
    }
    .body {
        background: #fbfbfb;
    }
    .ant-form-item {
        box-sizing: border-box;
        padding: 0;
        color: rgba(0,0,0,.65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        margin: 0 0 24px;
        vertical-align: top;
    }
    .search-form li, .search-form ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    //.search-form li {
    //    float: left;
    //    margin-right: 20px;
    //}

    .search-form .ant-form label {
        font-size: 12px !important;
    }

    .search-form {
        .ant-form {
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;

                li {
                    label {
                        font-size: 12px;
                    }
                }
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }


    .ant-row, .ant-row:after, .ant-row:before {
        display: flex;
    }


    .ant-table.ant-table-bordered .ant-table-container {
        border: 1px solid #f0f0f0;
        border-right: 0;
        border-bottom: 0;
    }


    .ant-table-thead > tr > th {
        background: #eee;
        padding: 9px 16px;
        font-size: 12px;
    }


    .page-wrap {
        width: 1200px;
        margin: 0 auto;

        .col-right {
            flex: 1;
            margin: 20px 0 0 20px;
            max-width: 1000px;


            .self-ant-card {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
            }
        }
    }

    .ant-table-expanded-row > .ant-table-cell {
        background: #f7f7f7;
        font-size: 11px;
        padding: 10px 20px;
        border-bottom: 9px solid #fff;
        border-top: 20px solid #fff;
    }


    .order-goods {
        display: flex;
        text-align: left;

        .order-goods-img {
            width: 80px;
            height: 80px;
            margin-right: 10px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

        .order-goods-info {
            display: flex;
            flex-direction: column;

            .center-space {
                margin: 0;
                flex: 1;
                width: 100%;
            }
        }
    }

</style>